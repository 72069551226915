<template>
  <div v-if="entry_by_route">
    <!-- :data-test="$slugify(entry_by_route.name)" -->
    <EntrySettings
      v-model="entry_by_route"
      @showSettings="showSettings = true"
      position="center"
      data-test="entry_page"
      data-tour="entry_page"
    />

    <RightSidebar
      :entry="entry_by_route"
      :show="showSettings"
      @hide="showSettings = false"
    />

    <EntryModalPage />
  </div>
</template>

<script>
import RightSidebar from "./helpers/RightSidebar.vue";
import { Statusing } from "./mixins/Statusing";
import { Scheduling } from "./mixins/Scheduling";
import { Routining } from "./mixins/Routining";
import EntryModalPage from "./EntryModalPage.vue";
import { Lazying } from "./mixins/Lazying";
export default {
  components: {
    RightSidebar,
    EntryModalPage,
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      entry: this.entry_by_route,
      depth: 0,
    };
  },
  mixins: [Statusing, Scheduling, Routining, Lazying],
  watch: {
    entry_by_route: function () {
      this.showSettings = false;
      if (this.entry_by_route)
        document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
      else {
        // setTimeout(() => {
        //   if (!this.entry_by_route) this.$router.push({ name: "home" });
        // }, 500);
      }

      // setTimeout(() => {
      //   this.showSettings = false;
      // }, 500);
    },
    "entry_by_route.name": function () {
      if (this.entry_by_route)
        document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
    },
    "$route.params.id": function () {
      this.pullData();
    },
    "$route.params.id2": function (id2) {
      /**
       * On closing a modal, we check if the id2
       * is now empty, and set the old title back
       */
      if (!id2) {
        if (this.entry_by_route) {
          document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
        }
      }
    },
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.pullData();
      });
    },
  },
  computed: {
    anchors() {
      return this.entries
        .filter((e) => e.anchor)
        .sort((a, b) => {
          if (a.anchor.position > b.anchor.position) return 1;
          if (a.anchor.position < b.anchor.position) return -1;
          return 0;
        });
    },
    status: {
      get() {
        return this.getStatusById(this.entry_by_route.status_id);
      },
      set(status) {
        this.setStatus(this.entry_by_route, status);
      },
    },

    statuses() {
      return this.getAvailableStatuses(this.entry_by_route);
    },
    user() {
      return this.$store.getters.user;
    },
    entry_by_route() {
      return this.entries?.find((e) => e.id == this.$route.params.id);
    },
    entry2() {
      return this.entries?.find((e) => e.id == this.$route.params.id2);
    },
    entries() {
      return this.$store.getters.entries?.filter((e) => e.deleted_at === null);
    },
    defaultOutput() {
      return [{ key: "links", op: "any", val: [this.entry_by_route.id] }];
    },
    outputIsModified() {
      return (
        this.entry_by_route.output &&
        JSON.stringify(this.defaultOutput) !=
          JSON.stringify(this.entry_by_route.output.data)
      );
    },

    linkedEntries() {
      return this.entry_by_route.links
        .map((id) => this.entries.find((e) => e.id == id))
        .filter((e) => e);
    },
  },
  data() {
    return {
      window: window,
      schema: null,
      showSettings: false,
      showTemplates: false,
      showEntryModal: true,
    };
  },
  created: function () {
    this.pullData();
    if (this.entry_by_route)
      document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
  },
  methods: {
    pullData() {
      if (this.$route.name == "entry") {
        if (!this.entry_by_route || this.entry_by_route?.deleted_at === null) {
          this.$store.dispatch("pull", {
            filters: [{ key: "id", op: "eq", val: this.$route.params.id }],
            cb: () => {
              if (!this.entry_by_route) {
                this.$router.push({ name: "home" });
              }
            },
          });
        } else {
          this.$router.push({ name: "home" });
        }
      }
    },
  },
};
</script>
