import { createStore } from "vuex";
import axios from "axios";
import { filterItemsByFilter } from "./filterResolving";
import moment from "moment";
import { nanoid } from "nanoid";



if (typeof Cypress !== "undefined") {
  axios.defaults.baseURL = "http://localhost:8000/api/"
} else if (process.env.NODE_ENV == 'development' && window.location.hostname == "localhost") {
  axios.defaults.baseURL = "http://localhost:8000/api/"
} else {
  axios.defaults.baseURL = "https://api.pensive.io/"
}

var syncPush = [];
// eslint-disable-next-line no-unused-vars
var inPushProcess = false;
var syncPushTimeOut = null;

var syncPull = [];
var syncPullStringified = [];
var syncPullTimeOut = null;
var syncPullStringifiedTimeOut = null;

export const store = createStore({
  state() {
    return {
      token: localStorage.getItem("access_token") || null,
      user: JSON.parse(localStorage.getItem("user")) || null,
      space: JSON.parse(localStorage.getItem("space")) || null,
      subscriptions: [],
      users: [],

      entries: JSON.parse(localStorage.getItem("entries")) || [],
      entriesClone: JSON.parse(localStorage.getItem("entries")) || [],

      histories: [],
      templates: [],

      timestamp: moment().unix(),
      clock: moment.utc().format("YYYY-MM-DD HH:mm:ss"),

      toasts: [],
      undos: [],
      opened: JSON.parse(localStorage.getItem("opened")) || [],
      selected: [],
      hovered: [],
      dragged: [],
      draggedBL: [],
      schema: null,

      initialized: false,

      registeredInputs: [],

      focus: null,
      share: null,

      delayedEntriesUpdate: [],

      widthSize: null, // 'xs', 'sm', 'md', 'lg', 'xl'
      mouseTarget: {},
    };
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    token(state) {
      return state.token;
    },
    user(state) {
      return state.user;
    },
    users(state) {
      return [state.user];
    },
    space(state) {
      return state.space;
    },
    statuses(state) {
      if (!state.space) return [];
      // first we need all entries with statuses
      var entries = state.entries.filter((e) => e.statuses.length);
      // then we will get all statuses from those entries
      var statuses = entries.flatMap((e) => e.statuses);
      // then we merge this with the space statuses
      return [...state.space.statuses, ...statuses];
    },
    subscriptions(state) {
      return state.subscriptions;
    },
    entries(state) {
      return state.entries;
    },
    entriesJson(state) {
      return JSON.stringify(state.entries);
    },
    entriesClone(state) {
      return state.entriesClone;
    },
    time_trackings(state) {
      return state.entries.flatMap((e) => e.time_trackings);
    },
    histories(state) {
      return state.histories;
    },
    templates(state) {
      return state.templates;
    },
    timestamp(state) {
      return state.timestamp;
    },
    clock(state) {
      return state.clock;
    },
    toasts(state) {
      return state.toasts;
    },
    undos(state) {
      return state.undos;
    },
    opened(state) {
      return state.opened;
    },
    selected(state) {
      return state.selected;
    },
    dragged(state) {
      return state.dragged;
    },
    draggedBL(state) {
      return state.draggedBL;
    },
    hovered(state) {
      return state.hovered;
    },
    schema(state) {
      return state.schema;
    },
    initialized(state) {
      return state.initialized;
    },
    registeredInputs(state) {
      return state.registeredInputs;
    },
    focus(state) {
      return state.focus;
    },
    share(state) {
      return state.share;
    },
    tours(state) {
      return state.tours;
    },
    delayedEntriesUpdate(state) {
      return state.delayedEntriesUpdate
    },
    widthSize(state) {
      return state.widthSize;
    },
    mouseTarget(state) {
      return state.mouseTarget;
    }

  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
      state.user = null;
      state.space = null;
      state.entries = [];
    },
    retrieveUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    retrieveSpace(state, space) {
      state.space = space;
      localStorage.setItem("space", JSON.stringify(space));
    },
    retrieveSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    destroyToken(state) {
      state.token = null;
      state.user = null;
      state.space = null;
      state.entries = [];
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("space");
      localStorage.removeItem("entries");
    },
    addEntry(state, entry) {
      state.entries.push(entry);
    },
    appendEntries(state, list) {
      for (var i = 0; i < list.response.length; i++) {
        var data = list.response[i];
        var entries = data.entries;
        var filters = data.filters;
        var _timestamp = data._ts;
        /*var preservationFilter =
          data.preservationFilter ||
          function () {
            return true;
          };*/
        // eslint-disable-next-line
        if (!data.limit && filters && filters.length /* && preservationFilter.length */) {
          var oldEntries = state.entries.filter(entry => {
            // if (entry.name == 'Whoop') {
            //   console.log(entry)
            //   console.log((moment.utc(entry.created_at).unix()))
            //   console.log((moment.utc(entry.updated_at).unix()))
            //   console.log(_timestamp, moment(_timestamp).unix());
            //   console.log((moment.utc(entry.created_at).unix()) < moment(_timestamp).unix() && (moment.utc(entry.updated_at).unix()) < moment(_timestamp).unix())
            // }

            return (moment.utc(entry.created_at).unix()) < moment(_timestamp).unix() && (moment.utc(entry.updated_at).unix()) < moment(_timestamp).unix()
          });
          //console.table(filters);
          filters.forEach((f) => {
            oldEntries = oldEntries.filter(filterItemsByFilter(f));
          });
          // console.log(
          //   oldEntries.map((e) => e.name)
          // );

          oldEntries.forEach((entry) => {
            const exists = entries.findIndex((e) => e.id == entry.id);
            if (exists == -1) {
              const index = state.entries.findIndex((e) => e.id == entry.id);
              if (index >= 0) {
                // console.log("removing", entry.name, entry.created_at, moment(_timestamp).format("YYYY-MM-DD HH:mm:ss"))
                state.entries.splice(index, 1);
              }
            }
          });
        }

        entries.forEach((entry) => {
          const index = state.entries.findIndex((i) => i.id == entry.id);
          if (index >= 0) {
            //console.log("appendi appendi?", entry.name, entry.updated_at, state.entries[index].updated_at, entry.updated_at >= state.entries[index].updated_at)

            // TODO This sounds good but right now doesnt work
            //if (entry.name == 'Cy - Scheduled - Today')
            //  console.log(entry.name, JSON.stringify(entry) != JSON.stringify(state.entries[index]), entry.updated_at, state.entries[index].updated_at)
            // if (JSON.stringify(entry) != JSON.stringify(state.entries[index]))
            if (entry.updated_at > state.entries[index].updated_at)
              state.entries[index] = entry;

          } else {
            state.entries.push(entry);
          }
        });
      }
      localStorage.setItem("entries", JSON.stringify(state.entries));
    },
    history(state, data) {
      state.histories = data;
      // if (!Array.isArray(data)) {
      //   data = [data]
      // }
      // state.histories = [...state.histories, ...data]
    },
    retrieveTemplates(state, data) {
      state.templates = data;
    },
    storeLocally(state) {
      // setTimeout(() => {
      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("space", JSON.stringify(state.space));
      localStorage.setItem("entries", JSON.stringify(state.entries));
      // }, 1000);
    },
    // eslint-disable-next-line no-unused-vars
    timestamp(state) {
      const timestamp = moment().unix();
      if (timestamp - state.timestamp > 0) {
        state.timestamp = timestamp;
      }
    },
    clock(state) {
      state.clock = moment.utc().format("YYYY-MM-DD HH:mm:ss");
    },
    set(state, data) {
      state[data.key] = data.val;
    },
    notifyUser(state, data) {
      state.toasts.push(data);
    },
    saveOld(state, entries) {
      state.entriesClone = entries;
    },
    saveNew(state, entries) {
      state.entries = entries;
    },
    opened(state, data) {
      state.opened = data;
      localStorage.setItem("opened", JSON.stringify(state.opened));
    },
    selected(state, entry) {
      if (entry === null) {
        state.selected = [];
        return;
      }

      if (state.selected.findIndex(id => id == entry.id) >= 0) {
        state.selected = state.selected.filter(id => id != entry.id);
        return;
      }
      state.selected = [...state.selected, ...[entry.id]];
    },
    dragged(state, entries) {
      state.dragged = entries;
    },
    draggedBL(state, entries) {
      state.draggedBL = entries;
    },
    hovered(state, list) {
      state.hovered = list;
    },
    schema(state, schema) {
      state.schema = schema;
    },
    initialized(state, initialized) {
      state.initialized = initialized;
    },
    registerInput(state, data) {
      if (state.registeredInputs.findIndex(i => i.id == data.id) >= 0) return;
      state.registeredInputs.push(data);
    },
    unregisterInput(state, data) {
      state.registeredInputs = state.registeredInputs.filter(i => i.id != data.id);
    },
    focus(state, entry) {
      state.focus = entry;
    },
    share(state, node) {
      state.share = node;
    },
    retrieveTours(state, tours) {
      state.tours = tours;
    },
    delayEntriesUpdate(state, entry) {
      state.delayedEntriesUpdate.push(entry);
      setTimeout(() => {
        state.delayedEntriesUpdate = state.delayedEntriesUpdate.filter(e => e.id != entry.id);
      }, 500);
    },
    setWidthSize(state, size) {
      // console.log(size)
      state.widthSize = size;
    },
    mouseTarget(state, target) {
      state.mouseTarget = target;
    }
  },
  actions: {
    set(context, data) {
      context.commit("set", data);
      context.commit("storeLocally");
    },
    undoManagement(context, entries) {
      var state = context.getters;
      var oldState = [];
      var changes = [];
      entries.forEach((entry) => {
        const index = state.entries.findIndex((e) => e.id == entry.id);
        if (state.entriesClone[index]) {
          oldState[index] = (JSON.parse(
            JSON.stringify(state.entriesClone[index])
          ));
          changes.push({
            after: JSON.parse(JSON.stringify(entry)),
            before: state.entriesClone[index],
          });
        } else {
          oldState[index] = () => {

            state.entries.splice(state.entries.findIndex(e => e.id == entry.id), 1);

            context.dispatch("push", {
              event: "entry_delete",
              params: { id: entry.id },
              entry: entry,
              undo: true,
            });
          };
          changes.push({
            after: JSON.parse(JSON.stringify(entry)),
            before: null,
          });
        }
      });
      context.commit("saveOld", JSON.parse(JSON.stringify(state.entries)));

      if (oldState.length) {
        var undo = {
          id: nanoid(),
          changes: changes,
          undo: () => {
            // state.undos.length = 0;
            oldState.forEach((oldEntry, index) => {
              if (oldEntry == null)
                return;
              if (typeof oldEntry == 'function') {
                oldEntry();
                return;
              }

              //console.log(index, oldEntry);
              state.entries[index] = oldEntry;

              context.dispatch("push", {
                event: "entry_update",
                params: { entry: oldEntry },
                entry: oldEntry,
                undo: true,
              });
            });

            context.commit("saveNew", state.entries);
            context.commit(
              "saveOld",
              JSON.parse(JSON.stringify(state.entries))
            );
            context.commit("storeLocally");

            const undoIndex = state.undos.findIndex((u) => u.id == undo.id);
            if (undoIndex >= 0) {
              state.undos.splice(
                undoIndex,
                1
              );
            }
          },
          close: () => {
            const undoIndex = state.undos.findIndex((u) => u.id == undo.id);
            if (undoIndex >= 0) {
              state.undos.splice(
                undoIndex,
                1
              );
            }
          },
        };

        if (state.undos.length > 2) {
          state.undos.shift();
        }
        if (navigator.userAgent.includes('Mobile') && state.undos.length > 0) {
          state.undos.shift();
        }

        state.undos.length = 0;
        state.undos.push(undo);

        setTimeout(() => {
          const undoIndex = state.undos.findIndex((u) => u.id == undo.id);
          if (undoIndex >= 0) {
            state.undos.splice(
              undoIndex,
              1
            );
          }
        }, 5000);
      }
    },
    push(context, data) {
      // console.trace();
      // console.log(data);

      inPushProcess = true;

      //return;
      // eslint-disable-next-line no-unreachable
      if (data.entry && !data.undo) {
        data.entry.updated_at = moment().utc().format("YYYY-MM-DD HH:mm:ss");
        // if (typeof Cypress === "undefined") {
        //   context.commit("delayEntriesUpdate", data.entry);
        // }
      }


      syncPush.push(data);

      // if (typeof Cypress !== "undefined") {
      //   context.dispatch("syncPush", syncPush);
      //   syncPush = [];
      //   syncPushTimeOut = null;
      // } else {
      if (!syncPushTimeOut) {
        syncPushTimeOut = setTimeout(() => {
          context.dispatch("syncPush", syncPush);
          syncPush = [];
          syncPushTimeOut = null;
        }, 250);
      }
      // }
    },
    syncPush(context, data) {

      context.commit("storeLocally");
      // console.log("SYNC PUSH")

      if (typeof Cypress == "undefined") {
        // Using new Set to remove duplicates
        context.dispatch("undoManagement", [...new Set(data.filter((d) => d.entry && !d.undo).map((d) => d.entry))]);
      }

      if (context.getters.loggedIn) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
        axios
          .post("/push", {
            events: data,
          })
          .catch((error) => {
            if (error.response?.data?.message) {
              if (error.response.data.message == "error_user_inactive") {
                if (
                  window.location.href !=
                  (window.location.hostname == "localhost"
                    ? "http://localhost:8080/profile"
                    : "https://pensive.io/app/profile")
                ) {
                  window.location.replace(
                    window.location.hostname == "localhost"
                      ? "http://localhost:8080/profile"
                      : "https://pensive.io/app/profile"
                  );
                }
              }
            } else {
              // todo sync push needs to be handled better
              if (process.env.NODE_ENV === 'development')
                context.commit("notifyUser", { message: "Something went wrong" });
            }
          })
          .then(() => {
            data
              .filter((d) => d.cb)
              .map((d) => d.cb)
              .forEach((d) => {
                d();
              });
          })
          .finally(() => {
            // console.log("DONE PUSHING")
            inPushProcess = false;
          });
      } else {
        data
          .filter((d) => d.cb)
          .map((d) => d.cb)
          .forEach((d) => {
            d();
          });

        inPushProcess = false;
      }
    },
    register(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("register", {
            firstname: credentials.firstname,
            lastname: credentials.lastname,
            email: credentials.email,
            password: credentials.password,
            password_confirmation: credentials.password_confirmation,
            timezone: credentials.timezone,
          })
          .then((response) => {
            const token = response.data.access_token;
            localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    forgotPassword(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("forgot-password", {
            email: credentials.email,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    resetPassword(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("reset-password", {
            email: credentials.email,
            token: credentials.token,
            password: credentials.password,
            password_confirmation: credentials.password_confirmation,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    resendValidation(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;

          axios
            .post("resend-validation")
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        });
      }
    },
    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("login", {
            username: credentials.email,
            password: credentials.password,
          })
          .then((response) => {
            const token = response.data.access_token;
            localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);

            //context.dispatch("retrieveUser", credentials.entries);
            resolve(response);
          })
          .catch((error) => {
            context.dispatch("destroyToken");
            reject(error.response);
          });
      });
    },
    retrieveTokenProvider(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("auth/" + payload.provider + "/redirect")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            context.dispatch("destroyToken");
            reject(error.response);
          });
      });
    },
    retrieveProviderCallback(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("auth/" + credentials.provider + "/callback", credentials)
          .then((response) => {
            const token = response.data.access_token;
            localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);

            // check if new is set to store all demo entries.
            //context.dispatch("retrieveUser", response.data.new ? credentials.entries : []);
            context.dispatch("retrieveUser");
            resolve(response);
          })
          .catch((error) => {
            context.dispatch("destroyToken");
            reject(error.response);
          });
      });
    },
    retrieveUser(context, entries) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;

          axios
            .get("user")
            .then((response) => {
              const user = response.data;
              context.commit("retrieveUser", user);
              if (moment.tz.guess() != user.timezone) {
                user.timezone = moment.tz.guess();
                axios.post("user", user);
              }
              context.dispatch("retrieveSpace", entries);
              // context.dispatch("retrieveStatuses", entries);
              resolve(response);
            })
            .catch((error) => {
              context.dispatch("destroyToken");
              reject(error);
            });
        });
      }
    },
    retrieveSpace(context/*, entries*/) {

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;

          axios
            .get("space")
            .then((response) => {
              const space = response.data;
              context.commit("retrieveSpace", space);

              // if (entries && entries.length) {
              //   var pullOnce = true;
              //   entries.forEach((entry) => {
              //     entry.space_id = space.id;

              //     context.dispatch("push", {
              //       event: "entry_create",
              //       params: { entry: entry },
              //       entry: entry,
              //       cb: () => {
              //         if (pullOnce) {
              //           context.dispatch("pull", {
              //             filters: [{ key: "anchor", op: "is set", val: [] }],
              //             chain: false,
              //           });
              //           pullOnce = false;
              //         }
              //       },
              //     });
              //   });
              // }

              resolve(response);
            })
            .catch((error) => {
              context.dispatch("destroyToken");
              reject(error);
            });
        });
      }
    },
    // retrieveStatuses(context/*, entries*/) {
    //   if (context.getters.loggedIn) {
    //     return new Promise((resolve, reject) => {
    //       axios.defaults.headers.common["Authorization"] =
    //         "Bearer " + context.state.token;

    //       axios
    //         .get("statuses")
    //         .then((response) => {
    //           const statuses = response.data;
    //           context.commit("retrieveStatuses", statuses);
    //           resolve(response);
    //         })
    //         .catch((error) => {
    //           context.dispatch("destroyToken");
    //           reject(error);
    //         });
    //     });
    //   }
    // },
    retrieveSubscriptions(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;

          axios
            .get("stripe/subscriptions")
            .then((response) => {
              context.commit("retrieveSubscriptions", response.data);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    destroyToken(context) {
      context.commit("destroyToken");
      context.commit("storeLocally");
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;
          axios
            .post("logout")
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    addEntry(context, entry) {
      context.commit("addEntry", entry);
    },
    pull(context, data) {

      // if (typeof Cypress !== "undefined") {
      //   alert(JSON.stringify(data))
      // }


      // console.log(data);
      // if (!data.filters) {

      //   console.log("WHAT THE FUCK")
      //   console.log(new Error().stack);
      // }


      if (context.getters.loggedIn) {

        const filtersStringified = JSON.stringify(data.filters);
        // console.log("pulling", filtersStringified)
        if (data.cb) {
          // console.log(data, filtersStringified);
        }

        if (syncPullStringified.includes(filtersStringified)) {
          // if (data.source?.entry == 'Household') console.log("Household skipped :)!")
          return;
        }


        syncPullStringified.push(filtersStringified)

        // if (data.source?.entry == 'Household') {

        //   console.log("Household added to syncPull")
        //   console.trace();
        // }


        syncPull.push(data);



        // eslint-disable-next-line no-constant-condition
        if (syncPull.length >= 10 /* || typeof Cypress !== "undefined" */) {
          // console.log("pulling " + syncPull.length + " filters")
          context.dispatch("syncPull", syncPull);
          syncPull = [];
          syncPullTimeOut = null;
        } else {
          if (syncPullTimeOut) clearTimeout(syncPullTimeOut)
          if (syncPullStringifiedTimeOut) clearTimeout(syncPullStringifiedTimeOut)

          syncPullTimeOut = setTimeout(() => {
            if (syncPull.length) {
              // console.log("pulling " + syncPull.length + " filters")
              context.dispatch("syncPull", syncPull);
              syncPull = [];
            }
            syncPullStringifiedTimeOut = setTimeout(() => {
              // @note this is needed to prevent the same filter to be pulled multiple times
              syncPullStringified = [];
              syncPullStringifiedTimeOut = null;
            }, 2000);
            syncPullTimeOut = null;
          }, 100);
        }
      }
    },
    syncPull(context, data) {
      context.commit("storeLocally");
      // console.log("SYNC PULL", syncPush.length, inPushProcess)
      if (syncPush.length || inPushProcess) {
        // console.log("still pushing, so delay pulling");
        setTimeout(() => {
          // console.log("retry...");
          context.dispatch("syncPull", data);
        }, 100);
        return;
      }
      /*if (context.getters.user && context.getters.user.current_space_id)
        data.space_id = context.getters.user.current_space_id;*/
      // console.log("syncPull")
      // console.log(data);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;

      axios
        .post("/pull", {
          filters: data,
        })
        .then((response) => {
          var links = [];
          response.data.forEach((d, k) => {
            if (typeof data[k].chain == "undefined" || data[k].chain != false) {
              var entries = d.entries;
              entries.forEach((entry) => {
                entry.links.forEach((id) => {
                  const index = context.getters.entries.findIndex(
                    (i) => i.id == id
                  );
                  if (index == -1) {
                    links.push(id);
                  }
                });
              });
            }
          });
          if (links.length) {
            /**
             * Okay, this is needed for the linked entries to be pulled as well. (in order to 
             * see them in the column entries section for example)
             * Maybe we can do this in a more elegant way later, for example retrieving the linked entries
             * only if they are not already in the store and only if we open up an entry?
             */
            context.dispatch("pull", {
              filters: [{ key: "id", op: "eq", val: [...new Set(links)] }],/*source: {
                file: 'Store',
                links: links,
              },*/
              chain: false,
            });
          }

          context.commit("appendEntries", {
            response: response.data,
            // data: data,
          });

          data
            .filter((d) => d.cb)
            .map((d) => d.cb)
            .forEach((d) => {
              d();
            });
        })
        .catch((error) => {


          if (process.env.NODE_ENV === 'development') {
            console.log(error);
            context.commit("notifyUser", { message: error.message });
          }

          if (error.response && error.response.status === 401) {
            context.dispatch('destroyToken');
            window.location.reload();
          }
        });
    },
    history(context, data) {
      context.commit("history", data);
    },
    retrieveTemplates(context/*, entries*/) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;

          axios
            .get("templates")
            .then((response) => {
              const templates = response.data;
              context.commit("retrieveTemplates", templates);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    retrieveHistory(context, entry) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;

          axios
            .post("history", {
              entry_id: entry.id,
            })
            .then((response) => {
              const histories = response.data;
              context.commit("history", histories);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    timestamp(context) {
      context.commit("timestamp");
    },
    clock(context) {
      context.commit("clock");
    },
    verifyEmail(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;

        axios
          .get(
            "email/verify/" +
            data.id +
            "/" +
            data.hash +
            "?expires=" +
            data.expires +
            "&signature=" +
            data.signature
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            //context.dispatch("destroyToken");
            reject(error);
          });
      });
    },
    opened(context, data) {
      context.commit("opened", data);
    },
    selected(context, entry) {
      context.commit("selected", entry);
    },
    dragged(context, entries) {
      context.commit("dragged", entries);
    },
    draggedBL(context, entries) {
      context.commit("draggedBL", entries);
    },
    hovered(context, list) {
      context.commit("hovered", list);
    },
    schema(context, schema) {
      context.commit("schema", schema);
    },
    initialized(context, initialized) {
      context.commit("initialized", initialized);
    },
    registerInput(context, data) {
      context.commit("registerInput", data);
    },
    unregisterInput(context, data) {
      context.commit("unregisterInput", data);
    },
    focus(context, entry) {
      context.commit("focus", entry);
    },
    share(context, node) {
      context.commit("share", node);
    },
    retrieveTours(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;

          axios
            .get("tours")
            .then((response) => {
              context.commit("retrieveTours", response.data);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateTour(context, data) {
      if (context.getters.loggedIn) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
        axios
          .put("/user/tours", data)
      }
    },
    mouseTarget(context, data) {
      context.commit("mouseTarget", data);
    }
  },
});
