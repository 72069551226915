<template>
  <PopoverHelper :teleport="true" class="flex items-center">
    <template v-slot:button>
      <ButtonComponent
        @click="date = $moment.utc().format('YYYY-MM-DD')"
        variant="round"
        :class="{
          'animate-pulse !bg-blue-500 !text-white hover:!bg-blue-600':
            activelyTimeTrackedEntry && !$store.getters.focus,
          '': !activelyTimeTrackedEntry || $store.getters.focus,
        }"
        size="lg"
        color="secondary"
        title="Time trackings today"
      >
        <span class="sr-only">Time Trackings</span>
        <IconHelper name="timer" size="24" aria-hidden="true" />
      </ButtonComponent>
    </template>
    <template v-slot="{ close }">
      <EntryDisplay
        v-if="activelyTimeTrackedEntry"
        v-model="activelyTimeTrackedEntry"
        :display="{
          senses: false,
          name: true,
          status: true,
          links: true,
          description: false,
          schedule: true,
          output: false,
          input: false,
          columns: false,
          leftover: false,
          time_trackings: true,
          routine: false,
        }"
        :disableViewportChecking="true"
        position="contextmenu"
        class="border-b p-5"
      />
      <OutputDisplay
        v-model="outputComputed"
        :filter="outputFilter"
        :display="{
          senses: false,
          name: true,
          status: false,
          links: false,
          description: false,
          schedule: false,
          output: false,
          input: false,
          columns: false,
          leftover: false,
          time_trackings: false,
          routine: false,
        }"
        :sorting="[{ field: 'updated_at', dir: -1 }]"
        position="contextmenu"
        @onEntryClick="
          () => {
            close();
          }
        "
        :editable="false"
        class="max-h-96 w-96 overflow-y-auto p-5 pb-2 text-sm"
      >
        <template v-slot:output-before="{ entries }">
          <div class="flex space-x-3">
            <div class="min-w-0 flex-1">
              <p
                class="text-sm font-semibold text-gray-900 dark:text-neutral-300"
              >
                Total Duration: {{ getTotalDurationOfEntries(entries, date) }}
              </p>
              <p class="text-sm text-neutral-500">
                <PopoverHelper :teleport="true">
                  <template v-slot:button>
                    <a href="#" class="hover:underline">
                      {{ $moment.utc(date).format("DD.MM.YYYY") }}
                    </a>
                  </template>
                  <template v-slot="{ close }">
                    <CalendarComponent
                      v-slot="{ date: _date }"
                      class="mb-1 mt-2"
                    >
                      <button
                        role="button"
                        @click="(date = _date), close()"
                        :class="{
                          'bg-gray-700 text-white hover:bg-gray-800':
                            _date == date,
                          'bg-white hover:bg-gray-50': _date != date,
                        }"
                        class="rounded-full px-3 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        <DateRenderComponent
                          :date="$moment.utc(_date)"
                          :format="'DD'"
                          :class="{
                            'text-gray-500': _date != date,
                          }"
                        />
                      </button>
                    </CalendarComponent>
                  </template>
                </PopoverHelper>
              </p>
            </div>
          </div>
        </template>
        <template v-slot:entry-top-right="{ entry }">
          <span class="font-mono text-xs">
            {{
              $moment
                .utc(
                  entry.time_trackings
                    .filter(
                      (tt) =>
                        $moment.utc(tt.start_at).format("YYYY-MM-DD") == date,
                    )
                    .map((tt) => {
                      return (
                        (tt.end_at
                          ? $moment.utc(tt.end_at).unix()
                          : $moment.utc(current).unix()) -
                        $moment.utc(tt.start_at).unix()
                      );
                    })
                    .reduce((a, b) => a + b, 0) * 1000,
                )
                .format("HH:mm:ss")
            }}
          </span>
        </template>
      </OutputDisplay>
      <!-- <div class="m-5 flex justify-end">
        <TimeTrackingsStacked :date="date" class="h-32 w-full" />
      </div> -->
      <div class="flex justify-end p-5 pt-2">
        <TimeTrackingsGrid :date="date" class="w-full" />
      </div>
    </template>
  </PopoverHelper>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { Tracking } from "../mixins/Tracking";
import TimeTrackingsGrid from "../timetrackings/TimeTrackingsGrid.vue";
export default {
  mixins: [Tracking],
  components: {
    TimeTrackingsGrid,
    OutputDisplay: defineAsyncComponent(() =>
      import("@/components/output/OutputDisplay.vue"),
    ),
  },
  data() {
    return {
      date: this.$moment.utc().format("YYYY-MM-DD"),
      current: null,
      interval: null,
    };
  },
  mounted() {
    this.current = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss");
    this.interval = setInterval(() => {
      this.current = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    outputComputed() {
      return {
        data: [
          {
            key: ["time_trackings", "range"],
            op: "ra",
            val: [this.date + " 00:00:00", this.date + " 23:59:59"],
          },
        ],
      };
    },
    user() {
      return this.$store.state.user;
    },
    hasActiveTimeTracking() {
      return this.entries
        .flatMap((e) => e.time_trackings)
        .filter((tt) => tt)
        .find((tt) => tt.end_at == null && tt.user_id == this.user.id);
    },
    outputFilter() {
      return (e) => {
        return (
          e.time_trackings.length &&
          e.time_trackings.filter(
            (tt) =>
              tt.user_id == this.user.id &&
              this.$moment.utc(tt.start_at).format("YYYY-MM-DD") == this.date,
          )
        ).length;
      };
    },
  },
};
</script>
