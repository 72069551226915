<template>
  <div
    class="flex max-w-full flex-wrap justify-stretch gap-2"
    @click="(e) => (!entry.temp ? e.stopPropagation() : null)"
  >
    <div
      v-for="(customField, index) in customFields"
      :key="index"
      class="flex flex-wrap items-center gap-2 rounded-md px-2 py-1 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-neutral-600 dark:ring-neutral-700"
    >
      <span class="select-none whitespace-nowrap text-gray-500 sm:text-sm">
        {{ customField.name }}
      </span>
      <div
        v-if="customField.type == 'rating'"
        class="flex flex-wrap gap-y-1"
        :data-test="'entry_cv_' + $slugify(customField.name)"
        :data-rating="customValues[index].value"
      >
        <!-- eslint-disable vue/no-mutating-props-->
        <ButtonComponent
          v-for="i in parseInt(
            customField.config?.count ? customField.config.count : 5,
          )"
          :key="i"
          @click="
            () => {
              customValues[index].value = i;
              const customValue = customValues[index];
              const existingCustomValue = entry.custom_values.find(
                (cv) => cv.id == customValue.id,
              );
              if (existingCustomValue) {
                existingCustomValue.value = customValue.value;
              } else {
                entry.custom_values.push(customValue);
              }
              if (!entry.temp)
                $store.dispatch('push', {
                  event: 'entry_update',
                  params: { entry: entry },
                  entry: entry,
                });
            }
          "
          @mouseover="hoveredRating[customField.id] = i"
          @mouseleave="hoveredRating[customField.id] = null"
          variant="minimal"
          :class="{
            'opacity-50 grayscale hover:opacity-100 hover:grayscale-0':
              (hoveredRating[customField.id] == null &&
                i > customValues[index].value) ||
              (hoveredRating[customField.id] != null &&
                hoveredRating[customField.id] < i),
          }"
          :title="i.toString()"
          class="px-1"
          :data-test="'rating_' + i"
        >
          <span
            v-html="customField.config?.char ? customField.config.char : '⭐️'"
          />
        </ButtonComponent>
      </div>
      <!-- eslint-disable vue/no-mutating-props-->
      <input
        v-else
        :type="customField.type"
        v-model="customValues[index].value"
        @input="
          (event) => {
            customValues[index].value = event.target.value;
          }
        "
        @keyup.enter="(event) => event.target.blur()"
        @blur="
          () => {
            const customValue = customValues[index];
            if (customValue.value) {
              const existingCustomValue = entry.custom_values.find(
                (cv) => cv.id == customValue.id,
              );
              if (existingCustomValue) {
                existingCustomValue.value = customValue.value;
              } else {
                entry.custom_values.push(customValue);
              }
              if (!entry.temp)
                $store.dispatch('push', {
                  event: 'entry_update',
                  params: { entry: entry },
                  entry: entry,
                });
            }
          }
        "
        :class="$style.input"
        :placeholder="
          customField.type.charAt(0).toUpperCase() + customField.type.slice(1)
        "
        :data-test="'entry_cv_' + $slugify(customField.name)"
      />
    </div>
  </div>
</template>

<script>
import { customValueBlueprint } from "@/addonBlueprint";
export default {
  props: {
    customFields: Array,
    entry: Object,
  },
  data() {
    return {
      hoveredRating: [],
    };
  },
  computed: {
    customValues() {
      return this.customFields?.map((cf) => {
        return {
          ...customValueBlueprint(this.entry, cf),
          ...this.entry.custom_values.find((cv) => cv.custom_field_id == cf.id),
        };
      });
    },
  },
};
</script>

<style module lang="scss">
.input {
  @apply inline flex-1 border-0 bg-transparent p-0 sm:text-sm sm:leading-6;
  color: var(--ps-base-text-color);
  &::placeholder {
    color: var(--ps-base-secondary-color);
  }
  &:focus {
    outline: none;
  }
}
</style>
