
/*import "normalize.css"*/
import './tooltips.css'

// eslint-disable-next-line no-unused-vars
import { createApp, h, nextTick } from "vue";
import { store } from "./store";
import * as Sentry from "@sentry/vue";
import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { createRouterScroller } from 'vue-router-better-scroller'
import Vue3TouchEvents from "vue3-touch-events";

// import dotenv from 'dotenv';

// dotenv.config();

import App from "./App.vue";
import routes from "./routes";

import moment from 'moment/moment';
import 'moment-duration-format';
import { Capacitor } from '@capacitor/core';

const WebviewConfiguratorPlugin = Capacitor.isPluginAvailable('WebviewConfiguratorPlugin');
import { setBackForwardNavigationGestures, setWebviewBounce } from "capacitor-plugin-ios-webview-configurator";
if (WebviewConfiguratorPlugin) {
  setBackForwardNavigationGestures(true)
  setWebviewBounce(true);
}

const isLocalhost = window.location.hostname == 'localhost';
const isElectron = navigator.userAgent.includes('Electron');

const enableAnalytics = (!isLocalhost || isElectron) && typeof Cypress === 'undefined';

if (enableAnalytics) {
  window._paq = window._paq = window._paq || [];

  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  //window._paq.push(["trackPageView"]);
  //window._paq.push(["enableLinkTracking"]);
  (function () {
    var u = "https://stats.marcoplain.com/";
    window._paq.push(["setTrackerUrl", u + "matomo.php"]);
    window._paq.push(["setSiteId", "4"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  })();
}

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
  setTimeout(() => {
    window.popStateDetected = false
  }, 1000)
})

const router = createRouter({
  history: isElectron && typeof Cypress == 'undefined' ? createWebHashHistory() : createWebHistory(isLocalhost ? "/" : "/"),
  routes,
  // scrollBehavior(to, from, savedPosition) {

  //   if (to.params.id2)
  //     return false;

  //   // if ((to.params.id2 || from.params.id2) && to.params.id != from.params.id2) {
  //   //   console.log("Lol")
  //   //   return false;
  //   // }
  //   if (savedPosition)
  //     return savedPosition
  //   return { top: 0 }
  // }
});

let startTime

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {

  if ((from.name === undefined && to.name === 'entry' && isLocalhost)) {
    app.config.globalProperties.$shouldTransition = true;
  } else {
    app.config.globalProperties.$shouldTransition = true;
  }

  if (from.name == 'login' && to.name == 'home' && app.config.globalProperties.$redirectAfterAuthTo) {
    const redirect = app.config.globalProperties.$redirectAfterAuthTo;
    app.config.globalProperties.$redirectAfterAuthTo = null;
    return redirect;
  }

  // console.trace();
  // console.log("ROUTE CHANGED", from.name, to.name)


  store.dispatch('initialized', false)
  startTime = performance.now()
  if (to.meta.requiresAuth && !store.getters.loggedIn) {
    app.config.globalProperties.$redirectAfterAuthTo = to;
    return {
      path: "/login",
    };
  }
  if (to.meta.requiresVisitor && store.getters.loggedIn) {
    return {
      path: "/",
    };
  }
  // If the route has a title, set it as the page title of the document/page
  if (to.meta.title) {
    document.title = `${to.meta.title} · Pensive`
  }
});

const onIdle = function (cb = () => { }) {
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}

router.afterEach(() => {
  nextTick(() => {
    onIdle(() => {
      store.dispatch('initialized', true)
    })
    const endTime = performance.now()
    // eslint-disable-next-line no-unused-vars
    const timeTaken = endTime - startTime
    // console.log(`Navigation took ${Math.round(timeTaken)} ms`)
  })
})

const app = createApp(App);

app.use(router);
app.use(createRouterScroller({
  selectors: {
    // eslint-disable-next-line no-unused-vars
    '#main': function ({ to, from, type, savedPosition, element }) {
      if (to.params.id2) {
        return false;
      }
      if (type === 'push' || (from.params.id2 && !to.params.id2 && from.params.id != to.params.id)) {
        return { top: 0 }
      }
      else if (type === 'history') {
        return savedPosition
      }
    }
  },
}))

// app.use(createRouterScroller({
//   selectors: {
//     //'window': true,
//     // custom handler for scrolling on `body`
//     body({ to, from, type, savedPosition, element }) {

//       console.log(type, savedPosition)
//       // navigation triggered by RouteLink or router.push
//       if (type === 'push') {

//         if (to.name == 'entry2' && from.name == 'entry') {
//           return false;
//         }

//         return { top: 0 } // disable scroll restoration
//       }

//       // navigation triggered by browser back/forward, or router.back()
//       else if (type === 'history') {
//         console.log("history", from.name, to.name)
//         /**
//          * There might be the case with $router.replace
//          * that has the type history, but we don't want to
//          * restore the position in that case
//          */
//         if (from.name == 'entry2' && to.name == 'entry') {
//           console.log("up you go", element)
//           // element.scrollTo({
//           //   top: 0,
//           //   left: 0,
//           //   behavior: 'smooth',
//           // })
//           return { top: 0 }
//         }

//         //return savedPosition
//         // custom handling
//         // element.scrollTo({
//         //   ...savedPosition,
//         //   behavior: 'smooth',
//         // })
//       }
//     },
//   },
// }))
if (!isLocalhost)
  Sentry.init({
    app,
    dsn: "https://d96f8e7adb4288cc38876400b3fb5046@o4507725461651456.ingest.de.sentry.io/4507725463683152",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.use(store);
app.use(Vue3TouchEvents);

import VueShepherdPlugin from 'vue-shepherd';
app.use(VueShepherdPlugin);

// GLOBAL COMPONENTS

import ApplicationShell from "./components/ApplicationShell.vue";
app.component('ApplicationShell', ApplicationShell)
import ApplicationHeader from "./components/ApplicationHeader.vue";
app.component('ApplicationHeader', ApplicationHeader)
import ApplicationFooter from "./components/ApplicationFooter.vue";
app.component('ApplicationFooter', ApplicationFooter)
import CommandPalette from "./components/partials/CommandPalette.vue";
app.component('CommandPalette', CommandPalette)
import FocusWrapper from "./components/partials/FocusWrapper.vue";
app.component('FocusWrapper', FocusWrapper)
import ShareWrapper from "./components/partials/ShareWrapper.vue";
app.component('ShareWrapper', ShareWrapper)

import IntersectionList from "./components/helpers/IntersectionList.vue";
app.component('IntersectionList', IntersectionList)

import IntersectionItem from "./components/helpers/IntersectionItem.vue";
app.component('IntersectionItem', IntersectionItem)

import DropZone from "./components/helpers/DropZone.vue";
app.component('DropZone', DropZone)

import EntryTest from "./components/entry/EntryTest.vue";
app.component('EntryTest', EntryTest)
import EntryDisplay from "./components/entry/EntryDisplay.vue";
app.component('EntryDisplay', EntryDisplay)
import EntrySettings from "./components/entry/EntrySettings.vue";
app.component('EntrySettings', EntrySettings)

import CoverDisplay from "./components/cover/CoverDisplay.vue";
app.component('CoverDisplay', CoverDisplay)

import CoverSettings from "./components/cover/CoverSettings.vue";
app.component('CoverSettings', CoverSettings)


import Search from "./components/entries/Search.vue";
// eslint-disable-next-line vue/multi-word-component-names
app.component('Search', Search)

import SearchResults from "./components/partials/SearchResults.vue";
app.component('SearchResults', SearchResults)

import LinkedComponent from "./components/rest/RestDisplay.vue";
app.component('LinkedComponent', LinkedComponent)

// ADDONS

import BreadcrumbsDisplay from './components/breadcrumbs/BreadcrumbsDisplay.vue';
app.component('BreadcrumbsDisplay', BreadcrumbsDisplay)

import NameDisplay from "./components/name/NameDisplay.vue";
app.component('NameDisplay', NameDisplay)

import SettingsDisplay from "./components/settings/SettingsDisplay.vue";
app.component('SettingsDisplay', SettingsDisplay)

import RestDisplay from "./components/rest/RestDisplay.vue";
app.component('RestDisplay', RestDisplay)

import StatusDisplay from "./components/status/StatusDisplay.vue";
app.component('StatusDisplay', StatusDisplay)
import StatusSettings from "./components/status/StatusSettings.vue";
app.component('StatusSettings', StatusSettings)
import StatusIndicator from "./components/status/partials/StatusIndicator.vue";
app.component('StatusIndicator', StatusIndicator)
import StatusOption from "./components/status/partials/StatusOption.vue";
app.component('StatusOption', StatusOption)

import ProcrastinationDisplay from "./components/procrastination/ProcrastinationDisplay.vue";
app.component('ProcrastinationDisplay', ProcrastinationDisplay)

import LinksDisplay from "./components/links/LinksDisplay.vue";
app.component('LinksDisplay', LinksDisplay)
import LinksSettings from "./components/links/LinksSettings.vue";
app.component('LinksSettings', LinksSettings)

import PriorityDisplay from "./components/priority/PriorityDisplay.vue";
app.component('PriorityDisplay', PriorityDisplay)
import PrioritySettings from "./components/priority/PrioritySettings.vue";
app.component('PrioritySettings', PrioritySettings)

import DescriptionDisplay from "./components/description/DescriptionDisplay.vue";
app.component('DescriptionDisplay', DescriptionDisplay)
import DescriptionSettings from "./components/description/DescriptionSettings.vue";
app.component('DescriptionSettings', DescriptionSettings)

import ScheduleDisplay from "./components/schedule/ScheduleDisplay.vue";
app.component('ScheduleDisplay', ScheduleDisplay)
import ScheduleSettings from "./components/schedule/ScheduleSettings.vue";
app.component('ScheduleSettings', ScheduleSettings)

import TimeTrackingsDisplay from "./components/timetrackings/TimeTrackingsDisplay.vue";
app.component('TimeTrackingsDisplay', TimeTrackingsDisplay)
import TimeTrackingsSettings from "./components/timetrackings/TimeTrackingsSettings.vue";
app.component('TimeTrackingsSettings', TimeTrackingsSettings)


import RoutineDisplay from "./components/routine/RoutineDisplay.vue";
app.component('RoutineDisplay', RoutineDisplay)
import RoutineSettings from "./components/routine/RoutineSettings.vue";
app.component('RoutineSettings', RoutineSettings)


import ColorSettings from "./components/color/ColorSettings.vue";
app.component('ColorSettings', ColorSettings)



import AnchorSettings from "./components/anchor/AnchorSettings.vue";
app.component('AnchorSettings', AnchorSettings)


import TemplateDisplay from "./components/templates/TemplateDisplay.vue";
app.component('TemplateDisplay', TemplateDisplay)
import TemplateSettings from "./components/templates/TemplateSettings.vue";
app.component('TemplateSettings', TemplateSettings)


// import OutputDisplay from "./components/output/OutputDisplay.vue";
// app.component('OutputDisplay', OutputDisplay)
// import OutputSettings from "./components/output/OutputSettings.vue";
// app.component('OutputSettings', OutputSettings)
// import OutputCount from "./components/output/partials/OutputCount.vue";
// app.component('OutputCount', OutputCount)

import InputDisplay from "./components/input/InputDisplay.vue";
app.component('InputDisplay', InputDisplay)
import InputSettings from "./components/input/InputSettings.vue";
app.component('InputSettings', InputSettings)



// import SensesDisplay from "./components/senses/SensesDisplay.vue";
// app.component('SensesDisplay', SensesDisplay)
import SensesSettings from "./components/senses/SensesSettings.vue";
app.component('SensesSettings', SensesSettings)

import StatusesSettings from "./components/statuses/StatusesSettings.vue";
app.component('StatusesSettings', StatusesSettings)


import CustomFieldsDisplay from "./components/custom_fields/CustomFieldsDisplay.vue";
app.component('CustomFieldsDisplay', CustomFieldsDisplay)

import CustomFieldsSettings from "./components/custom_fields/CustomFieldsSettings.vue";
app.component('CustomFieldsSettings', CustomFieldsSettings)




import TemplateWrapper from "./components/helpers/TemplateWrapper.vue";
app.component('TemplateWrapper', TemplateWrapper)

import DatePicker from './components/builders/DatePicker.vue';
app.component('DatePicker', DatePicker)

import TimePicker from './components/builders/TimePicker.vue';
app.component('TimePicker', TimePicker)

import CalendarComponent from "./components/helpers/CalendarComponent.vue";
app.component('CalendarComponent', CalendarComponent)


// import CalendarDatePicker from "./components/helpers/calendar/CalendarDatePicker.vue";
// app.component('CalendarDatePicker', CalendarDatePicker)

import CalendarHelper from "./components/helpers/calendar/CalendarHelper.vue";
app.component('CalendarHelper', CalendarHelper)

// import CalendarMonthHelper from "./components/helpers/calendar/CalendarMonthHelper.vue";
// app.component('CalendarMonthHelper', CalendarMonthHelper)

// import CalendarWeekHelper from "./components/helpers/calendar/CalendarWeekHelper.vue";
// app.component('CalendarWeekHelper', CalendarWeekHelper)

// import CalendarDayHelper from "./components/helpers/calendar/CalendarDayHelper.vue";
// app.component('CalendarDayHelper', CalendarDayHelper)

import ContainerComponent from "./components/helpers/ContainerComponent.vue";
app.component('ContainerComponent', ContainerComponent)

import ButtonComponent from './components/helpers/ButtonComponent'
app.component('ButtonComponent', ButtonComponent)

import OverlayComponent from './components/helpers/OverlayComponent'
app.component('OverlayComponent', OverlayComponent)

import PopoverHelper from './components/helpers/PopoverHelper'
app.component('PopoverHelper', PopoverHelper)

import PopoverTitle from './components/helpers/PopoverTitle'
app.component('PopoverTitle', PopoverTitle)

import ProgressBarHelper from './components/helpers/ProgressBarHelper'
app.component('ProgressBarHelper', ProgressBarHelper)

import LabelHelper from './components/helpers/LabelHelper'
app.component('LabelHelper', LabelHelper)

import TitleHelper from './components/helpers/TitleHelper'
app.component('TitleHelper', TitleHelper)

import SelectMenu from './components/helpers/SelectMenu'
app.component('SelectMenu', SelectMenu)


import SmoothText from './components/helpers/SmoothText'
app.component('SmoothText', SmoothText)


import BulkSelectMenu from "./components/partials/BulkSelectMenu.vue";
app.component('BulkSelectMenu', BulkSelectMenu)

import EmptyState from "./components/helpers/EmptyState.vue";
app.component('EmptyState', EmptyState)


import {
  EllipsisHorizontalIcon, QuestionMarkCircleIcon, EyeIcon, TrashIcon, PlusCircleIcon, PlusIcon, CheckIcon, XMarkIcon, ArrowRightIcon, XCircleIcon, CheckCircleIcon, Cog6ToothIcon, ChevronLeftIcon, ChevronRightIcon, FunnelIcon, ChevronUpIcon, ChevronDownIcon, ChevronUpDownIcon, EllipsisVerticalIcon, LinkIcon, DocumentTextIcon, PuzzlePieceIcon, PencilIcon, ListBulletIcon, SquaresPlusIcon,
  ArrowUpIcon,
  ArrowDownIcon, Squares2X2Icon, SwatchIcon, HeartIcon
} from '@heroicons/vue/24/outline';
// import { } from '@heroicons/vue/24/solid';
app.component('ArrowUpIcon', ArrowUpIcon)
app.component('ArrowDownIcon', ArrowDownIcon)
app.component('EllipsisHorizontalIcon', EllipsisHorizontalIcon)
app.component('QuestionMarkCircleIcon', QuestionMarkCircleIcon)
app.component('EllipsisVerticalIcon', EllipsisVerticalIcon)
app.component('EyeIcon', EyeIcon)
app.component('TrashIcon', TrashIcon)
app.component('PlusCircleIcon', PlusCircleIcon)
app.component('PlusIcon', PlusIcon)
app.component('CheckIcon', CheckIcon)
app.component('XMarkIcon', XMarkIcon)
app.component('ArrowRightIcon', ArrowRightIcon)
app.component('XCircleIcon', XCircleIcon)
app.component('CheckCircleIcon', CheckCircleIcon)
app.component('Cog6ToothIcon', Cog6ToothIcon)
app.component('ChevronUpIcon', ChevronUpIcon)
app.component('ChevronDownIcon', ChevronDownIcon)
app.component('ChevronUpDownIcon', ChevronUpDownIcon)

app.component('ChevronLeftIcon', ChevronLeftIcon)
app.component('ChevronRightIcon', ChevronRightIcon)
app.component('PuzzlePieceIcon', PuzzlePieceIcon)
app.component('DocumentTextIcon', DocumentTextIcon)
app.component('PencilIcon', PencilIcon)
app.component('FunnelIcon', FunnelIcon)
app.component('LinkIcon', LinkIcon)
app.component('ListBulletIcon', ListBulletIcon);
app.component('SquaresPlusIcon', SquaresPlusIcon);
app.component('Squares2X2Icon', Squares2X2Icon);
app.component('SwatchIcon', SwatchIcon);
app.component('HeartIcon', HeartIcon);


import TransitionGroupHelper from './components/helpers/TransitionGroupHelper'
app.component('TransitionGroupHelper', TransitionGroupHelper)
import TransitionDelayHelper from './components/helpers/TransitionDelayHelper'
app.component('TransitionDelayHelper', TransitionDelayHelper)

// DIALOG / MODALS


import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dialog', Dialog)
app.component('DialogTitle', DialogTitle)
app.component('DialogPanel', DialogPanel)
app.component('TransitionChild', TransitionChild)
app.component('TransitionRoot', TransitionRoot)

import ModalHelper from './components/helpers/ModalHelper'
app.component('ModalHelper', ModalHelper)

import MenuHelper from './components/helpers/MenuHelper'
app.component('MenuHelper', MenuHelper)

import ListboxHelper from './components/helpers/ListboxHelper'
app.component('ListboxHelper', ListboxHelper)

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

// eslint-disable-next-line vue/multi-word-component-names
app.component('Listbox', Listbox)
app.component('ListboxButton', ListboxButton)
app.component('ListboxLabel', ListboxLabel)
app.component('ListboxOption', ListboxOption)
app.component('ListboxOptions', ListboxOptions)


import AlertHelper from './components/helpers/AlertHelper'
app.component('AlertHelper', AlertHelper)

import ChartHelper from './components/helpers/ChartHelper'
app.component('ChartHelper', ChartHelper)

import RoundedProgressBarHelper from './components/helpers/RoundedProgressBarHelper'
app.component('RoundedProgressBarHelper', RoundedProgressBarHelper)

import DateRenderComponent from './components/partials/DateRenderComponent'
app.component('DateRenderComponent', DateRenderComponent)

import DropdownComponent from './components/partials/dropdown/DropdownComponent'
app.component('DropdownComponent', DropdownComponent)
import DropdownOptionComponent from './components/partials/dropdown/DropdownOptionComponent'
app.component('DropdownOptionComponent', DropdownOptionComponent)

import InputWithLabel from './components/partials/InputWithLabel'
app.component('InputWithLabel', InputWithLabel)

import ConstructionArea from './components/helpers/ConstructionArea'
app.component('ConstructionArea', ConstructionArea)

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
// eslint-disable-next-line vue/multi-word-component-names
app.component('Menu', Menu)
app.component('MenuButton', MenuButton)
app.component('MenuItem', MenuItem)
app.component('MenuItems', MenuItems)

import IconHelper from './components/helpers/IconHelper'
app.component('IconHelper', IconHelper)

import CardHelper from './components/helpers/CardHelper'
app.component('CardHelper', CardHelper)

import PanelHelper from './components/helpers/PanelHelper'
app.component('PanelHelper', PanelHelper)

import TourHelper from './components/helpers/TourHelper'
app.component('TourHelper', TourHelper)


import { Markdown } from "tiptap-markdown";
Markdown.configure({
  html: true,                  // Allow HTML input/output
  tightLists: true,            // No <p> inside <li> in markdown output
  tightListClass: 'tight',     // Add class to <ul> allowing you to remove <p> margins when tight
  bulletListMarker: '-',       // <li> prefix in markdown output
  linkify: true,              // Create links from "https://..." text
  breaks: true,               // New lines (\n) in markdown input are converted to <br>
  transformPastedText: false,  // Allow to paste markdown text in the editor
  transformCopiedText: false,  // Copied text is transformed to markdown
})


// GLOBAL METHODS

import { formattedDateOp } from './datesHelper';
app.config.globalProperties.$formattedDateOp = formattedDateOp

import { resolveField } from './filterResolving';
app.config.globalProperties.$resolveField = resolveField

app.config.globalProperties.$slugify = function (title) {
  if (!title) return '';
  if (typeof title.toLowerCase !== 'function') {
    console.error("Title::toLowerCase is not a function", title, typeof title);
  }
  var slug = title.toLowerCase();
  slug = slug.replace(/\s*$/g, "");
  slug = slug.replace(/\s+/g, "-");
  return slug;
}

app.config.globalProperties.$window = window;
app.config.globalProperties.$document = document;
app.config.globalProperties.$console = console;
app.config.globalProperties.$setTimeout = setTimeout;
moment.locale('en-gb')
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$anime = anime;

const merge = require('deepmerge')
app.config.globalProperties.$merge = merge;

app.config.globalProperties.$nanoid = nanoid;

app.config.globalProperties.$cursorPosition = {
  x: screen.width / 2,
  y: screen.height / 2,
};
document.onmousemove = function (event) {
  app.config.globalProperties.$cursorPosition = {
    x: event.pageX - window.scrollX,
    y: event.pageY - window.scrollY,
  };
}
document.ontouchstart = function (event) {
  const touch = event.touches[0];
  app.config.globalProperties.$cursorPosition = {
    x: touch.pageX - window.scrollX,
    y: touch.pageY - window.scrollY,
  };
}
document.ontouchmove = function (event) {
  const touch = event.touches[0];
  app.config.globalProperties.$cursorPosition = {
    x: touch.pageX - window.scrollX,
    y: touch.pageY - window.scrollY,
  };
}


app.config.globalProperties.$windowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.onresize = function () {
  app.config.globalProperties.$windowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}
app.config.globalProperties.$scroll = {
  x: window.scrollX,
  y: window.scrollY,
};

window.onscroll = function () {
  app.config.globalProperties.$scroll = {
    x: window.scrollX,
    y: window.scrollY,
  };
}

app.config.globalProperties.$h = h;
app.config.globalProperties.$onIdle = onIdle
// MOUNTIN
import './app.scss'
import { nanoid } from 'nanoid';
import anime from 'animejs';

app.config.globalProperties.$version = process.env.VUE_APP_VERSION;

app.config.globalProperties.$metaKey = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl+';

// isMobile wasnt reactive. I replaced every ocurrence with a "navigator.userAgent.includes("Mobile")" check
// app.config.globalProperties.$isMobile = navigator.userAgent.includes('Mobile');
// app.config.globalProperties.$navigator = navigator;

app.config.globalProperties.$isLocalhost = isLocalhost;
app.config.globalProperties.$isElectron = isElectron;
app.config.globalProperties.$enableAnalytics = enableAnalytics;
app.config.globalProperties.$Cypress = typeof Cypress !== 'undefined';

// app.config.performance = true;
app.mount("#app");