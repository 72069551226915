<template>
  <div id="left-sidebar" :class="$style.leftSidebar" :style="cssProps">
    <div class="flex shrink-0 items-center">
      <div class="mt-5 w-full">
        <div class="flex flex-1 items-center justify-between overflow-visible">
          <div class="flex-1 truncate px-2">
            <span class="text-neutral-900 dark:text-neutral-200">
              <Logo />
            </span>
            <p class="font-mono text-xs text-neutral-700 dark:text-neutral-400">
              {{ space?.active_subscription.name }}
            </p>
          </div>
          <div class="flex-shrink-0">
            <ButtonComponent
              @click="$router.push({ name: 'space' })"
              variant="round"
              class="inline-flex h-8 w-8 items-center justify-center !bg-transparent !text-neutral-500 hover:!text-neutral-700 dark:!text-neutral-300 dark:hover:!text-neutral-100"
              title="Space settings"
              data-test="space_options"
            >
              <span class="sr-only">Open options</span>
              <IconHelper
                name="sliders-horizontal"
                size="16"
                stroke-width="1.75"
                aria-hidden="true"
              />
            </ButtonComponent>
          </div>
        </div>
      </div>

      <!--<img
                    class="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=600"
                    alt="Your Company"
                  />-->
    </div>
    <nav class="flex flex-1 flex-col">
      <div class="relative flex flex-1 flex-col gap-y-7" data-test="menu">
        <!-- <div
          ref="background"
          class="absolute h-8 w-full rounded-xl bg-neutral-900 shadow-xl shadow-neutral-100 hover:bg-neutral-950 dark:bg-neutral-300 dark:!text-black dark:shadow-none dark:hover:!bg-neutral-200"
        /> -->
        <component :is="'style'">
          body:not(:has(#draggable-wrapper)) .{{
            $style.leftSidebar
          }}
          [data-entry-id="{{ $route.params.id }}"] {
          --ps-output-display-entry-display-background-color:
          var(--ps-base-primary-color);
          --ps-output-display-entry-display-background-color-hover:
          var(--ps-base-primary-color);
          --ps-output-display-entry-display-text-color:
          var(--ps-base-background-color);
          --ps-output-display-entry-display-text-color-hover:
          var(--ps-base-background-color); } body:not(:has(#draggable-wrapper))
          .{{ $style.leftSidebar }} [data-entry-id="{{ $route.params.id }}"]
          .name_display { --ps-name-display-color:
          var(--ps-base-background-color)!important; }
        </component>

        <OutputDisplay
          :id="outputId"
          v-model="originsEntry.output"
          :entry="originsEntry"
          :display="{
            status: false,
            links: false,
            description: false,
            schedule: false,
            output: true,
            output_count: true,
            input: false,
            columns: false,
            leftover: false,
            settings: true,
            senses: true,
            routine: false,
            time_trackings: false,
            custom_fields: false,
          }"
          :sorting="originsEntry.output.sorting"
          position="left"
          :disableViewportChecking="false"
          :entriesDisabled="true"
          @onEntryClick="$emit('onEntryClick')"
          :editable="false"
          size="sm"
          @created="
            (entry) =>
              $nextTick(() => {
                this.$router.push({
                  name: 'entry',
                  params: { id: entry.id },
                });
              })
          "
          :overrideOnDrop="handleAnchorSorting"
          :class="$style.outputDisplay"
        />
        <!-- @onInitialized="handleBackground"
          @onToggleRenderOutput="() => $nextTick(handleBackground)" -->
        <div
          class="mt-auto flex w-full flex-col gap-y-0.5 rounded-2xl bg-neutral-50 p-5 dark:bg-neutral-900"
        >
          <a
            v-for="(link, index) in links"
            :key="index"
            :href="link.url"
            target="_blank"
            :class="link.classes"
            class="text-sm leading-6 text-neutral-700 hover:text-neutral-950 focus-visible:outline-neutral-500 dark:text-neutral-300 dark:hover:text-neutral-200"
          >
            {{ link.text }}
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Logo from "../Logo.vue";
import { Schema } from "../mixins/Schema";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";
import { Sorting } from "../mixins/Sorting";
import { defineAsyncComponent, hydrateOnIdle } from "vue";

export default {
  mixins: [Schema, Filtering, Sorting, Grouping],
  components: {
    Logo,
    OutputDisplay: defineAsyncComponent({
      loader: () => import("@/components/output/OutputDisplay.vue"),
      hydrate: () => hydrateOnIdle(),
    }),
  },
  props: {
    outputId: {
      type: String,
      default: () => {
        return "navigation";
      },
    },
  },
  data() {
    return {
      asdasd: "BxtpgGJ78fihdGWY5cENZ",
      originsEntry: {
        temp: true,
        name: "Add Entry",
        output: {
          type: "list",
          grouping: [],
          data: [{ key: "anchor", op: "is set", val: [] }],
          sorting: [{ field: "anchor", dir: 1 }],
        },
        input: {
          data: {
            anchor: {
              // position: { op: "append" },
            },
          },
        },
        links: [],
      },
      links: [
        {
          url: "https://pensive.io/whats-new",
          text: "v." + this.$version,
          classes: "font-mono text-xs font-bold !text-black dark:!text-white",
        },
        {
          url: "https://forms.gle/B5hi53AM9PZsR4jz5",
          text: "Report a Bug",
          classes: "",
        },
        {
          url: "https://forms.gle/PQXmMKPwGQQhAin7A",
          text: "Request a Feature",
          classes: "",
        },
      ],
    };
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    entries() {
      console.log("leftsidebar:entries updated");
      return this.$store.getters.entries;
    },
    anchoredEntries() {
      return this.filterEntries(this.originsEntry.output.data).sort(
        this.sortEntries(this.originsEntry.output.sorting),
      );
      // .filter(this.filter)
      // .sort(this.sortEntries(this.sorting))
      // .sort((a, b) => {
      //   if (this.output.custom_sorting) {
      //     const indexA = this.output.custom_sorting.indexOf(a.id);
      //     if (indexA == -1) return 1;
      //     // console.log("A", a.name, indexA);

      //     const indexB = this.output.custom_sorting.indexOf(b.id);
      //     if (indexB == -1) return -1;
      //     // console.log("B", b.name, indexB);
      //     return indexA - indexB;
      //   }
      //   return 0;
      // });
    },

    cssProps() {
      return {
        "--ps-output-display-entry-display-background-color-hover": this.$store
          .getters.dragged.length
          ? "transparent"
          : null,
      };
    },
  },
  methods: {
    handleAnchorSorting(schema) {
      let dragged = this.$store.getters.dragged;
      let newIndex = schema.dropIndex;

      let anchor_sorting = this.anchoredEntries.map((e) => e.id);

      dragged.forEach((entry) => {
        const oldIndex = anchor_sorting.indexOf(entry.id);
        // console.log("oldIndex", oldIndex);
        if (oldIndex > -1) {
          anchor_sorting.splice(oldIndex, 1);
          if (newIndex > oldIndex) {
            newIndex = newIndex - 1;
          }
        }
        // console.log("newIndex", newIndex);
        anchor_sorting.splice(newIndex, 0, entry.id);

        // custom_sorting_filtered.splice(index, 0, entry.id);
      });

      // console.log(
      //   "anchor_sorting",
      //   anchor_sorting.map((id) => this.entries.find((e) => e.id == id).name),
      // );
      setTimeout(() => {
        // this.$nextTick(() => {
        let position = 0;
        anchor_sorting.forEach((id) => {
          let index = this.$store.getters.entries.findIndex((e) => e.id == id);
          let entry = this.$store.getters.entries.find((e) => e.id == id);
          entry = { ...entry, anchor: { ...entry.anchor, position: position } };
          this.$store.getters.entries[index] = entry;

          // console.log(entry.name, entry.anchor.position);

          this.$store.dispatch("push", {
            event: "anchor_update",
            params: { anchor: entry.anchor },
            entry: entry,
            undo: true,
          });

          position++;
        });
        // });
      }, 0);
    },
  },
};
</script>

<style module lang="scss">
.leftSidebar {
  @apply flex grow flex-col gap-y-5 overflow-y-auto overflow-x-hidden px-4 pb-4;

  --ps-output-display-padding-x: 1rem;

  --ps-entry-display-padding-y: 0.5rem;
  --ps-entry-display-padding-x: 1rem;

  --ps-name-display-font-size: var(--ps-font-size-sm);
  --ps-name-display-line-height: var(--ps-line-height-sm);

  --ps-senses-display-flex-direction: row;

  scrollbar-gutter: stable;
  overflow: auto;
  background-color: var(--ps-base-background-color);

  border-right: 1px solid var(--ps-base-border-color);

  // scrollbar-width: thin;
  // @media (min-width: 1024px) {
  @media (hover: hover) {
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
    // }
  }
}

.leftSidebar :global(.name_display) {
  --ps-name-display-color: var(--ps-base-primary-color);
}

.outputDisplay {
  --ps-output-display-group-wrapper-list-row-gap: 0;
}
</style>
