<template>
  <div v-if="entries.length">
    <section
      style="flex-direction: column; align-items: flex-start"
      data-test="entry_rest_section"
    >
      <button
        @click="showOutput = !showOutput"
        class="flex items-center gap-2"
        data-test="toggle_show_linked_button"
      >
        <span
          :class="{
            'bg-neutral-100 shadow-xl dark:bg-neutral-900': showOutput,
          }"
          class="rounded-full p-1.5"
        >
          <span
            :class="{
              'text-pensive-blue': showOutput,
              'text-neutral-500': !showOutput,
            }"
          >
            <ChevronRightIcon
              :class="{ 'rotate-90': showOutput }"
              class="h-3 w-3 transition duration-200"
            />
          </span>
        </span>

        <span
          :class="{
            'font-semibold text-neutral-900 dark:text-neutral-400': showOutput,
            'text-neutral-500': !showOutput,
          }"
          class="text-xs uppercase"
        >
          {{ entries?.length }} Linked Entries
        </span>
      </button>
      <OutputDisplay
        v-if="showOutput"
        v-model="defaultOutput"
        ref="output"
        :entry="entry"
        :filter="filter"
        :sorting="entry.output?.sorting"
        :editable="false"
        :showInput="false"
        :position="position"
        class="mt-5"
      />
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent, hydrateOnIdle } from "vue";
import { Filtering } from "../mixins/Filtering";
import { Schema } from "../mixins/Schema";

export default {
  props: {
    modelValue: Object,
    inputs: Array,
    position: String,
  },
  watch: {
    entry: {
      handler() {
        this.showOutput = !this.entry.output;
        this.skipLengthCheck = true;
      },
      deep: true,
    },
    "entries.length": function (n, o) {
      if (n > o && o == 0 && !this.skipLengthCheck) {
        this.showOutput = true;
      }
      this.skipLengthCheck = false;
    },
  },
  data() {
    return {
      showOutput: true,
      skipLengthCheck: false,
      filter: (e) =>
        !this.entry.output ||
        this.outputComputed.findIndex((i) => i.id == e.id) == -1,
    };
  },
  mounted() {
    this.showOutput = !this.entry.output;
    this.pullData();
  },
  methods: {
    pullData() {
      this.$nextTick(() => {
        this.$store.dispatch("pull", {
          filters: this.defaultOutput.data,
        });
      });
    },
  },
  mixins: [Schema, Filtering],
  components: {
    OutputDisplay: defineAsyncComponent({
      loader: () => import("@/components/output/OutputDisplay.vue"),
      hydrate: () => hydrateOnIdle(),
    }),
  },
  computed: {
    entry() {
      return this.modelValue;
    },
    outputComputed() {
      return this.filterEntries(this.outputSchema(this.entry));
    },
    entries() {
      return this.filterEntries(this.defaultOutput.data).filter(this.filter);
    },
    defaultOutput() {
      return { data: [{ key: "links", op: "any", val: [this.entry.id] }] };
    },
  },
};
</script>
