<template>
  <Listbox as="div" v-model="color">
    <ListboxLabel
      v-if="!hideLabel"
      class="block text-sm font-medium leading-6 text-gray-900"
    >
      Color
    </ListboxLabel>
    <div class="relative" :class="{ 'mt-2': !hideLabel }">
      <ListboxButton
        class="relative w-full cursor-default rounded-md bg-white py-0.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-neutral-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-100 dark:ring-neutral-700 dark:hover:bg-neutral-900"
      >
        <span class="flex items-center">
          <span
            :class="[
              'bg-' + (color || 'neutral') + '-400',
              'inline-block h-2 w-2 flex-shrink-0 rounded-full',
            ]"
            aria-hidden="true"
          />
          <span class="ml-3 block truncate capitalize">{{
            color || "No Color"
          }}</span>
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full min-w-[10rem] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-black"
        >
          <ListboxOption
            as="template"
            v-if="color"
            :value="null"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active
                  ? 'bg-neutral-600 text-white dark:text-neutral-100 '
                  : 'text-gray-900 dark:text-neutral-100',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
              :data-test="`entry_aspect_color_none`"
            >
              <div class="flex items-center">
                <span
                  :class="[
                    'bg-neutral-400',
                    'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                  ]"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'ml-3 block truncate capitalize',
                  ]"
                >
                  Clear
                </span>
              </div>
            </li>
          </ListboxOption>

          <ListboxOption
            as="template"
            v-for="color in colors"
            :key="color"
            :value="color"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active
                  ? 'bg-neutral-600 text-white dark:text-neutral-100'
                  : 'text-gray-900 dark:text-neutral-100',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
              :data-test="'color_set_' + color"
            >
              <div class="flex items-center">
                <span
                  :class="[
                    'bg-' + color + '-400',
                    'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                  ]"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'ml-3 block truncate capitalize',
                  ]"
                >
                  {{ color }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-neutral-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

const colors = [
  "gray",
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
];
</script>
<script>
export default {
  props: {
    modelValue: String,
    hideLabel: Boolean,
  },
  computed: {
    color: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update", value);
      },
    },
  },
};
</script>
