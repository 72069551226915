export const Searching = {

  data() {
    return {
      searchOutput: [],
      searchTimeOut: null,
      searchFocusIndex: -1,
      searchSorting: [{ field: 'updated_at', dir: -1 }],
    };
  },
  computed: {
    searchResults() {
      return this.filterEntries(this.searchOutput)
        .sort(this.sortEntries(this.searchSorting));
    },
  },
  methods: {

    searchEntriesByName(name) {
      this.inputText = name;
      if (this.inputText != "") {
        this.searchOutput = [
          { key: "name", op: "any", val: this.inputText.toLowerCase() },
        ];
      } else {
        this.searchOutput = [];
      }
    }
  }
};
